import React from "react";
import eleganceSky from "../../assest/elegance-sky.png";
import shridi from "../../assest/logo-shridi.png";
import hijabiz from "../../assest/hijabiz.png";
import "./Project.css";
import { useTranslation } from "react-i18next";

const Project2 = () => {
  const { t } = useTranslation();

  return (
    <div id="project" className="projects">
      <div className="container">
        <h1 className="subTitle">{t("project_title")}</h1>
        <div className="workList">
          <div className="work">
            <a href="http://elegance-sky.com/" target="_blank" rel="noopener noreferrer"
            >
              <img src={eleganceSky} alt="ELEGANCE_SKY_SHOP"/>
              <div className="layer">
                <h3>{t("project1_h3")}</h3>
                <p> {t("project1_p")}</p>
              </div>
            </a>
          </div>
          <div className="work">
            <a href="http://shridi-egy.com/" target="_blank" rel="noopener noreferrer"
            >
              <img src={shridi} alt="shridi"/>
              <div className="layer">
                <h3>{t("project2_h3")}</h3>
                <p> {t("project2_p")}</p>
              </div>
            </a>
          </div>
          <div className="work">
            <a href="https://hijabiz.de/">
              <img src={hijabiz} alt="hijabiz" />
              <div className="layer">
                <h3>{t("project3_h3")}</h3>
                <p> {t("project3_p")}</p>
              </div>
            </a>
          </div>
        </div>
        <div className="containerButton2">
          <a className="button" href="/Projects">
            <span>{t("project_button")}</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default Project2;
