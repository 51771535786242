import React from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import "./Navbar.css";
import { useTranslation } from "react-i18next";
import logo from "../../assest/logo.png";
import { NavHashLink } from 'react-router-hash-link';

const NavbarItem = () => {

  const { t, i18n } = useTranslation();
  document.body.dir = i18n.dir();
  return (
    <>
      <Navbar collapseOnSelect expand="lg" variant="dark" className="navbar">
        <Container>
          <Navbar.Brand href="#">
            <img src={logo} alt="" />
          </Navbar.Brand>
          <Navbar.Toggle className="toggle" aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <NavHashLink  className="link linkcontact" to="/#contact">
                {t("contact")}
              </NavHashLink>
              <NavHashLink className="link" to="/">
                {t("home")}
              </NavHashLink>
              <NavHashLink className="link" to="/Projects">
                {t("projects")}
              </NavHashLink>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavbarItem;
