import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import translateEn from './locale/en/translation.json';
import translateDe from './locale/de/translation.json';
import translateAr from './locale/ar/translation.json';
import HttpApi from 'i18next-http-backend';
const resources = {
  en: {
    translation: translateEn
  },
  de: {
    translation: translateDe
  },
  ar: {
    translation: translateAr
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    suppotedLngs: ["en", "ar", "de"],
    resources,
    fallbackLng: 'en',
    detection: {
      order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'],
      caches: ['cookie'],
    },
    backend: {
      loadPath: './locale/{{lng}}/translation.json',
    },
    react: {
      useSuspense: false
    },
  });

  export default i18n;