import React, { useState, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./Common/Navbar/Navbar";
import Home from "./Pages/Home/Home";
import Cv from "./Pages/Cv/Cv";
import Projects from "./Pages/Projects/Projects";
import CustomizedSwitches from "./Components/demo";
import AOS from "aos";
import "aos/dist/aos.css";
import i18next from "i18next";

function App() {
  const [language, setLanguage] = useState(() => {
    const savedLanguage = localStorage.getItem("language");
    return savedLanguage ? savedLanguage : "en";
  });

  const handleChange = (e) => {
    const selectedLanguage = e.target.value;
    i18next.changeLanguage(selectedLanguage);
    localStorage.setItem("language", selectedLanguage);
    setLanguage(selectedLanguage);
  };

  const [theme, setTheme] = useState(() => {
    const initialTheme = localStorage.getItem("theme");
    return initialTheme ? initialTheme : "dark";
  });

  function getThemeFromLocalStorage() {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setTheme(savedTheme);
    }
  }

  function toggleTheme() {
    setTheme((prevTheme) => {
      const newTheme = prevTheme === "dark" ? "light" : "dark";
      localStorage.setItem("theme", newTheme);
      return newTheme;
    });
  }

  useEffect(() => {
    getThemeFromLocalStorage();
  }, [theme]);

  useEffect(() => {
    document.body.className = theme;
  }, [theme]);

  useEffect(() => {
    AOS.init({ duration: 2500 });
  }, []);

  return (
    <div className={`App ${theme}`}>
      <script src="https://unpkg.com/aos@2.3.1/dist/aos.js"></script>

      <Router>
        <div className="buttons">
          <div className="slected-box">
            <select value={language} onChange={handleChange}>
              <option value="en">English</option>
              <option value="de">Deutsch</option>
              <option value="ar">العربية</option>
            </select>
          </div>
          <div className="darkMode" onClick={toggleTheme}>
            <CustomizedSwitches />
          </div>
        </div>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/hisham-mosajo-cv" element={<Cv />} />
          <Route exact path="/Projects" element={<Projects />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
