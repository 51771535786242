import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./Skills.css";
import {useTranslation} from "react-i18next";

const Skill = () => {
    const {t} = useTranslation();

    const responsive = {
        superLargeDesktop: {
            breakpoint: {max: 4000, min: 3000},
            items: 5,
        },
        desktop: {
            breakpoint: {max: 3000, min: 1024},
            items: 3,
        },
        tablet: {
            breakpoint: {max: 1024, min: 767},
            items: 2,
        },
        mobile: {
            breakpoint: {max: 767, min: 0},
            items: 1,
        },
    };

    const skillsData = [
        {image: require("../../assest/skills/backend2.jpg"), title: "skills1"},
        {image: require("../../assest/skills/frontend.jpg"), title: "skills2"},
        {image: require("../../assest/skills/mobileDeveloper.jpg"), title: "skills3"},
        {image: require("../../assest/skills/java2.jpg"), title: "skills4"},
        {image: require("../../assest/skills/c.png"), title: "skills5"},
        {image: require("../../assest/skills/xamarin.png"), title: "skills6"},
        {image: require("../../assest/skills/react.png"), title: "skills7"},
        {image: require("../../assest/skills/reactNative.png"), title: "skills8"},
        {image: require("../../assest/skills/Bootstrap.png"), title: "skills9"},
        {image: require("../../assest/skills/JSP.png"), title: "skills10"},
        {image: require("../../assest/skills/HTML.png"), title: "skills11"},
        {image: require("../../assest/skills/CSS.png"), title: "skills12"},
        {image: require("../../assest/skills/JavaScript.png"), title: "skills13"},
        {image: require("../../assest/skills/Vaadin.png"), title: "skills14"},
        {image: require("../../assest/skills/JSON.png"), title: "skills16"},
        {image: require("../../assest/skills/REST-API.png"), title: "skills17"},
        {image: require("../../assest/skills/PostgreSQL.png"), title: "skills18"},
        {image: require("../../assest/skills/microsoftsql.png"), title: "skills19"},
        {image: require("../../assest/skills/AngularJS.png"), title: "skills20"},
        {image: require("../../assest/skills/Gradel.png"), title: "skills21"},
    ];

    return (
        <div className="skills" data-aos="zoom-in">
            <section className="skill" id="skills">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="skill-bx wow zoomIn">
                                <h2>{t("skills")}</h2>
                                <p>{t("skills_text")}</p>
                                <Carousel
                                    responsive={responsive}
                                    infinite={true}
                                    className="owl-carousel owl-theme skill-slider"
                                >
                                    {skillsData.map((skill, index) => (
                                        <div className="item" key={index}>
                                            <img src={skill.image} className="skills-image" alt="Skill"/>
                                            <h5>{t(skill.title)}</h5>
                                        </div>
                                    ))}
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};
export default Skill;
