import React from "react";
import img from "../../assest/web.png";
import "./AboutMe.css";
import { useTranslation } from "react-i18next";

function calculateYearsDifference(startDate, endDate) {
  // Get the current date (today)

  // Calculate the time difference in milliseconds
  const timeDifference = endDate - startDate;

  // Calculate the difference in years
  const millisecondsPerYear = 1000 * 60 * 60 * 24 * 365.25; // Account for leap years
  const yearsDifference = timeDifference / millisecondsPerYear;

  // Use Math.floor to round down to the nearest whole number
  const wholeYearsDifference = Math.ceil(yearsDifference);

  return wholeYearsDifference;
}

const AboutMe = () => {
  const { t } = useTranslation();

  return (
    <>
      <div className="about" id="about">
        <div className="container">
          <div className="aboutContainer row">
            <div className="colContainer col-12 col-md-8">
              <h1> {t("about")}</h1>
              <div className="Aboutbox">
                <div>
                <div className="box m-2" data-aos="flip-left">
                  <i className="bi bi-check-circle-fill"></i>
                  <h4> {t("about_box1_h4")}</h4>
                  <p>
                    {" "}
                    +
                    {calculateYearsDifference(
                      new Date("01.02.2020"),
                      new Date("01.01.2024")
                    )}{" "}
                    {t("years")}
                  </p>
                </div>
                <div className="box m-2" data-aos="flip-left">
                  <i className="bi bi-check-circle-fill"></i>
                  <h4> {t("about_box2_h4")}</h4>
                  <p>
                    {" "}
                    +
                    {calculateYearsDifference(
                      new Date("01.09.2017"),
                      new Date("01.02.2020")
                    )}{" "}
                    {t("years")}
                  </p>
                </div>
                </div>
                <div>
                <div className="box m-2" data-aos="flip-left">
                  <i className="bi bi-check-circle-fill"></i>
                  <h4> {t("about_box3_h4")}</h4>
                  <p>
                    {" "}
                    +
                    {calculateYearsDifference(
                      new Date("01.10.2014"),
                      new Date()
                    )}{" "}
                    {t("years")}
                  </p>
                </div>
                <div className="box m-2" data-aos="flip-left">
                  <i className="bi bi-check-circle-fill"></i>
                  <h4> {t("about_box4_h4")}</h4>
                  <p>
                    {" "}
                    +
                    {calculateYearsDifference(
                      new Date("01.01.2024"),
                      new Date()
                    )}{" "}
                    {t("years")}
                  </p>
                </div>
                </div>
              
              </div>
              <p className="aboutText">{t("aboutText")}</p>
            </div>
            <div className="col-12 col-md-4 manSection">
              <div className="man">
                <img className="aboutImg" src={img} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutMe;
