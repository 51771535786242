import React from "react";
import Data from "./Data";
import "./Home.css";

const HomeData = () => {
  return (
    <div>
      <section className="home" id="home">
        <div className="homeContainer">
          <div className="homeContent">
            <div className="rightSide" data-aos="fade-right">
              <Data />
            </div>
            <div className="homeImg" data-aos="fade-left"></div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default HomeData;
