import React from "react";
import "./Projects.css";
import project1 from "../../assest/project-1.png";
import eleganceSky from "../../assest/elegance-sky.png";
import shridi from "../../assest/logo-shridi.png";
import hijabiz from "../../assest/hijabiz.png";
import talabat from "../../assest/Talabat EU.png";
import morwareinigung from "../../assest/morwareinigung.png";
import { useTranslation } from "react-i18next";

const Projects = () => {
  const { t } = useTranslation();

  return (
    <div>
      <div id="project" className="projects">
        <div className="container">
          <h1 className="subTitle">{t("project_title")}</h1>
          <div className="workList">
            <div className="work">
              <a
                href="http://elegance-sky.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={eleganceSky} alt="LEGANCE_SKY_SHOP" />
                <div className="layer">
                  <h3>{t("project1_h3")}</h3>
                  <p> {t("project1_p")}</p>
                </div>
              </a>
            </div>
            <div className="work">
              <a
                href="https://shridi-egy.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={shridi} alt="shridi" />
                <div className="layer">
                  <h3>{t("project2_h3")}</h3>
                  <p> {t("project2_p")}</p>
                </div>
              </a>
            </div>
            <div className="work">
              <a
                href="https://hijabiz.de/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={hijabiz} alt="hijabiz" />
                <div className="layer">
                  <h3>{t("project3_h3")}</h3>
                  <p> {t("project3_p")}</p>
                </div>
              </a>
            </div>
            {/*<div className="work">*/}
            {/*  <a*/}
            {/*    href="https://talabat-eu.com/"*/}
            {/*    target="_blank"*/}
            {/*    rel="noopener noreferrer"*/}
            {/*  >*/}
            {/*    <img src={talabat} alt="talabat" />*/}
            {/*    <div className="layer">*/}
            {/*      <h3>{t("project4_h3")}</h3>*/}
            {/*      <p> {t("project4_p")}</p>*/}
            {/*    </div>*/}
            {/*  </a>*/}
            {/*</div>*/}

            <div className="work">
              <a
                href="https://morwareinigung.de/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img className="bg-dark" src={morwareinigung} alt="" />
                <div className="layer">
                  <h3>{t("project5_h3")}</h3>
                  <p> {t("project5_p")}</p>
                </div>
              </a>
            </div>
            <div className="work">
              <img src={project1} alt="" />
              <div className="layer">
                <h3>{t("project6_h3")}</h3>
                <p> {t("project6_p")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
