import React, { useState } from "react";
import "./Cv.css";
import educationData from "./education.json";
import experiencesData from "./experience.json";
import projectsData from "./projects.json";
import img from "../../assest/hishamo.jpg";
import { useTranslation } from "react-i18next";

const Cv = () => {
  const { t } = useTranslation();
  const [activeSection, setActiveSection] = useState("CV");

  const [currentProjectIndex, setCurrentProjectIndex] = useState(0);

  const handleNext = () => {
    if (currentProjectIndex < projectsData.projects.length - 1) {
      setCurrentProjectIndex(currentProjectIndex + 1);
    }
  };

  const handlePrev = () => {
    if (currentProjectIndex > 0) {
      setCurrentProjectIndex(currentProjectIndex - 1);
    }
  };

  return (
    <div className="cv">
      <div className="cvContainer">
        <div className="leftSide">
          <div className="profileText">
            <div className="imgBx">
              <img src={img} alt="" />
            </div>
            <h2>
              {t("HISHAM_MOSAJO")}
              <br />
              <span>{t("Computer engineer")}</span>
            </h2>
          </div>
          <div className="contactInfo">
            <ul className="ul">
              <li>
                <span className="iconCv">
                  <i className="bi bi-envelope"></i>
                </span>
                <span>
                  <a href="mailto:hisham.mosajo@gmail.com">
                    hisham.mosajo@gmail.com
                  </a>
                </span>
              </li>
              <li>
                <span className="iconCv">
                  <i className="bi bi-geo-alt"></i>
                </span>
                <span>Essen, Deutschland</span>
              </li>
              <li>
                <span className="iconCv">
                  <i className="bi bi-globe"></i>
                </span>
                <span>hisham-mosajo.com</span>
              </li>
            </ul>
            <ul>
              <li>
                <span className="iconCv">
                  <i className="bi bi-facebook"></i>
                </span>
                <span>
                  <a
                    href="https://www.facebook.com/h.mosajo90"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    H.MOSAJO90
                  </a>
                </span>
              </li>
              <li>
                <span className="iconCv">
                  <i className="bi bi-linkedin"></i>
                </span>
                <span>
                  <a
                    href="https://www.linkedin.com/in/hisham-mosajo-22564817b/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    HISHAM-MOSAJO
                  </a>
                </span>
              </li>
            </ul>
          </div>

          <div className="contactInfo langauge">
            <h3>{t("Languages")}</h3>
            <ul>
              <li>
                <p className="arab">
                  {t("Arabic")} {t("Mother language")}
                </p>
                <p>{t("German")} (C1)</p>
                <p>{t("English")} (C1)</p>
              </li>
            </ul>
          </div>

          <div className="contactInfo experience">
            <h3>{t("skills")}</h3>
            <ul>
              <li>
                <p className="arab">Java, Spring boot</p>
                <p>JavaScript, Angular, Vitest</p>
                <p>PostgreSQL, Hibernate</p>
                <p>Web development</p>
              </li>
            </ul>
          </div>
          <div className="contactInfo sonst">
            <h3>{t("Else")}</h3>
            <div className="SONST">
              <i data-aos="flip-left" className="bi bi-camera"></i>
              <i data-aos="flip-left" className="bi bi-car-front"></i>
              <i data-aos="flip-left" className="bi bi-music-note-beamed"></i>
              <i data-aos="flip-left" className="bi bi-book"></i>
              <i data-aos="flip-left" className="bi bi-bicycle"></i>
            </div>
          </div>
        </div>

        <div className="cvrightSide">
          <div className="navTabs">
            <button
              onClick={() => setActiveSection("CV")}
              className={activeSection === "CV" ? "active-tab" : ""}
            >
              CV
            </button>
            <button
              onClick={() => setActiveSection("Projects")}
              className={activeSection === "Projects" ? "active-tab" : ""}
            >
              {t("projects")}
            </button>
          </div>

          {activeSection === "CV" && (
            <div data-aos="fade-up">
              <div className="aboutMe">
                <h3>{t("aboutMe")}</h3>
                <p
                  style={{
                    whiteSpace: "pre-line",
                    lineHeight: "1.6",
                    maxWidth: "600px",
                  }}
                >
                  {t("about_text")}
                </p>
              </div>

              <div className="ERFAHRUNGEN">
                <h3>{t("experiences")}</h3>
                <ul>
                  {experiencesData.experiences.map((item, index) => (
                    <li key={index}>
                      <p>{t(item.experiences)}</p>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="education">
                <ul>
                  <h3>{t("EDUCATION_AND_TRAINING")}</h3>
                  {educationData.education.map((item, index) => (
                    <li key={index}>
                      <p>
                        <strong>
                          ({item.year}) {t(item.location)}
                        </strong>
                      </p>
                      <p>{t(item.course)}</p>
                    </li>
                  ))}
                </ul>
              </div>

              <div className="textInfo">
                <h3>{t("Knowledge-h3")}</h3>
                <p>{t("experience2")}</p>
                <p>{t("experience1")}</p>
                <p>{t("experience3")}</p>
                <p>{t("experience4")}</p>
                <p>{t("experience5")}</p>
                <p>{t("experience6")}</p>
                <p>{t("experience7")}</p>
                <p>{t("experience8")}</p>
                <p>{t("experience9")}</p>
              </div>

              <div className="ERFAHRUNGEN eRFAHRUNGEN">
                <ul>
                  <h3>{t("communicationSkills")}</h3>
                  <li>
                    <p>{t("communicationSkills_Text")}</p>
                  </li>
                </ul>
              </div>

              <div className="ORGANISATIONS">
                <ul>
                  <h3>{t("ORGANIZATIONAL_AND_MANAGERIAL_TALENT")}</h3>
                  <li>
                    <p>{t("ORGANIZATIONAL_AND_MANAGERIAL_TALENT_Text")} </p>
                  </li>
                </ul>
              </div>
            </div>
          )}

          {activeSection === "Projects" && (
            <div data-aos="fade-up" style={{ position: "relative" }}>
              <div
                key={projectsData.projects[currentProjectIndex].id}
                className="projectSection"
                style={{
                  height: "850px",
                  overflowY: "auto",
                }}
              >
                <div className="projectDate">
                  <p>
                    {t(
                      projectsData.projects[currentProjectIndex].projectDateKey
                    )}
                    <br />
                    {t(
                      projectsData.projects[currentProjectIndex].projectNameKey
                    )}
                  </p>
                </div>

                <div className="projectDesc">
                  <h3>{t("PROJECT_DESCRIPTION")}</h3>
                  <p>
                    {t(
                      projectsData.projects[currentProjectIndex].descriptionKey
                    )}
                  </p>
                </div>

                <div className="technologies">
                  <h3>{t("TECHNOLOGIES_USED")}</h3>
                  <ul className="tech-list">
                    {projectsData.projects[
                      currentProjectIndex
                    ].technologies.map((technologies, index) => (
                      <li key={index}>{t(technologies)}</li>
                    ))}
                  </ul>
                </div>

                <div className="RESPONSIBILITIES">
                  <h3>{t("OWN_RESPONSIBILITIES_IN_THE_PROJECT")}</h3>
                  <ul className="tech-list">
                    {projectsData.projects[
                      currentProjectIndex
                    ].responsibilities.map((responsibility, index) => (
                      <li key={index}>{t(responsibility)}</li>
                    ))}
                  </ul>
                </div>
              </div>

              <div className="navigationButtons">
                <button
                  onClick={handlePrev}
                  disabled={currentProjectIndex === 0}
                >
                  {t("prev")}
                </button>
                <span>
                  {currentProjectIndex + 1} / {projectsData.projects.length}{" "}
                </span>
                <button
                  onClick={handleNext}
                  disabled={
                    currentProjectIndex === projectsData.projects.length - 1
                  }
                >
                  {t("Next")}
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Cv;
