import React from "react";
import "./MoreInfo.css";
import img from "../../assest/icon-books.png";
import { useTranslation } from "react-i18next";

const Education = () => {
  const { t } = useTranslation();

  return (
    <div className="moreInfo">
      <div className="container">
        <div className="allItem row">
          <div className="col-sm">
            <div className="infoBox">
              <h5>{t("box1_h5")}</h5>
              <p> {t("box1_p")}</p>
            </div>
          </div>
          <div className="col-sm">
            <div className="infoBox">
              <h5>{t("box2_h5")}</h5>
              <p> {t("box2_p")}</p>
            </div>
          </div>
          <div className="col-sm">
            <div className="infoBox">
              <h5>{t("box3_h5")}</h5>
              <p> {t("box3_p")}</p>
            </div>
          </div>
        </div>
        <div className="allItem boxContain row">
          <div className="col-sm-4">
            <div className="leftBox">
              <h5>{t("box4_h5")}</h5>
              <p>{t("box4_p")}</p>
              <div className="containerButton">
                <a className="button" href="#contact">
                  <span>{t("button_box")}</span>
                </a>
              </div>
            </div>
          </div>
          <div className="col-sm-8">
            <div className="boximg">
              <img src={img} alt="" />
              <div className="textBox">
                <h5>{t("box5_h5")}</h5>
                <p>{t("box5_p")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Education;
