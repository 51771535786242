import React from "react";
import HomeData from "../../Components/HomeData/HomeData";
import Skills from "./../../Components/Skills/Skills";
import AboutMe from "./../../Components/AboutMe/AboutMe";
import MoreInfo from "../../Components/MoreInfo/MoreInfo";
// import Freelancer from "../../Components/Freelancer/Freelancer";
import Project from "../../Components/Project/Project";
import Contact from "../../Components/Contact/Contact";
const Home = () => {
  return (
    <>
      {/* SECTIONS  */}
      <HomeData />
      <Skills />
      <AboutMe />
      <MoreInfo />
      {/*<Freelancer />*/}
      <Project />
      <Contact />
    </>
  );
};

export default Home;
