import React, { useRef, useState } from "react";
import "./Contact.css";
import emailjs from "@emailjs/browser";
import { useTranslation } from "react-i18next";

const Result = () => {
  const { t } = useTranslation();
  return <p className="result">{t("contact_result")}</p>;
};

const Contact = () => {
  const { t } = useTranslation();

  const [isFormVisible, setFormVisibility] = useState(true);
  const [formBorderClass, setFormBorderClass] = useState("orange-border");

  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_1y0d87u",
        "template_qzgfxy5",
        form.current,
        "LOgv-M0cfXOj33wW3"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");

          setFormBorderClass("green-border");
          setFormVisibility(false);
          e.target.reset();
        },
        (error) => {
          console.log(error.text);
          e.target.reset();
        }
      );
  };

  return (
    <div id="contact">
      <div className="formBody">
        <div className="row">
          <div className="formHolder">
            <div className="formContent">
              <div className={`formItems ${formBorderClass}`}>
                <h3>{t("contact_h3")}</h3>
                <p>{t("contact_p")}</p>

                {isFormVisible && (
                  <form
                    onSubmit={sendEmail}
                    ref={form}
                    className="requires-validation"
                  >
                    <div className="col-md-12">
                      <input
                        type="text"
                        className="formControl"
                        id="validationDefault01"
                        placeholder="Full name"
                        name="fullName"
                        required
                      />
                    </div>
                    <div className="col-md-12">
                      <input
                        type="email"
                        placeholder="Email"
                        className="formControl"
                        id="inputEmail4"
                        name="email"
                      />
                    </div>
                    <div className="col-md-12">
                      <input
                        type="text"
                        placeholder="Title"
                        className="formControl"
                        id="titel"
                        required
                        name="titel"
                      />
                    </div>

                    <div className="col-md-12">
                      <label
                        htmlFor="exampleFormControlTextarea1"
                        className="label form-label"
                      ></label>
                      <textarea
                        className="formControl"
                        id="exampleFormControlTextarea1"
                        rows="5"
                        placeholder="Message"
                        name="message"
                      ></textarea>
                    </div>
                    <div className="buttonContact col-12">
                      <div className="containerButton">
                        <a className="button" href="#contact">
                          <span>
                            <button type="submit" className="btnCard">
                              {t("btnCard")}
                            </button>
                          </span>
                        </a>
                      </div>
                    </div>
                  </form>
                )}

                {!isFormVisible && <Result />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
